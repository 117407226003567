<template>
	<div>
		<component :is="componentName" />
	</div>
</template>

<script>
import { VUE_APP_VERSION } from '@/helpers/environment'
import { mapGetters, mapMutations } from 'vuex'

import { os, version, browserEngine, browserName, browserVersion } from '@/helpers/device'
import OpenTelemetry from '@/modules/OpenTelemetry'
import Jet from '@/modules/Jet'

import LandingMale from '@/views/landings/LandingMale.vue'
import LandingFemale from '@/views/landings/LandingFemale.vue'
import LandingActivity from '@/views/landings/LandingActivity.vue'
import LandingAgeSelection from '@/views/landings/LandingAgeSelection.vue'
import LandingGenderSelection from '@/views/landings/LandingGenderSelection.vue'

export default {
	name: 'LandingSwap',
	components: {
		LandingMale,
		LandingFemale,
		LandingActivity,
		LandingAgeSelection,
		LandingGenderSelection,

		// Experimental ⬇️
		LandingOnboardingStyle: () =>
			import(/* webpackChunkName: "landing-onboarding-style" */ '@/views/landings/LandingOnboardingStyle.vue'),

		LandingForbes: () => import(/* webpackChunkName: "landing-forbes" */ '@/views/landings/LandingForbes.vue'),
		HealthCoach: () => import(/* webpackChunkName: "your-health-coach" */ '@/views/personal-plan/HealthCoach.vue'),
	},

	computed: {
		...mapGetters({
			language: 'i18n/getLanguage',
			hostname: 'location/hostname',
			getQueryParam: 'location/getQueryParam',
			isForbesAffiliateTraffic: 'location/isForbesAffiliateTraffic',
			appAreaConfig: 'location/getAppAreaConfig',
			onetrustActiveGroups: 'onetrust/getActiveGroups',
			userStep: 'survey/getUserStepCount',
			onboardingStep: 'survey/getOnboardingStepCount',
		}),

		componentName() {
			if (this.component) {
				return this.component
			}

			if (this.hostname === 'your-health-coach.com') {
				return 'HealthCoach'
			}

			if (this.IS_AGE_SELECTION_LANDING_TYPE && this.AREA.BAU_MAIN) {
				return 'LandingAgeSelection'
			}

			if (this.IS_SPREADSHEET_CREATIVES_EXP) {
				return 'LandingFemale'
			}

			if (this.isForbesAffiliateTraffic) {
				return 'LandingForbes'
			}

			if (this.AREA.WALKING_MALE || this.AREA.YOGA_MALE || this.AREA.CHAIR_WORKOUTS_MALE || this.AREA.MAIN_PILATES) {
				return 'LandingAgeSelection'
			}

			if (
				this.AREA.ACTIVITY_FEMALE ||
				this.AREA.ACTIVITY_MALE ||
				this.AREA.YOGA_FEMALE ||
				this.AREA.SOMATIC_FEMALE ||
				this.AREA.WALKING_FEMALE
			) {
				return 'LandingActivity'
			} else if (this.AREA.BAU_FEMALE || this.AREA.PILATES_FEMALE) {
				return 'LandingFemale'
			} else if (this.AREA.BAU_MALE) {
				return 'LandingMale'
			} else if ([this.AREA.ACTIVITY_MAIN, this.AREA.WALKING_MAIN, this.AREA.YOGA_MAIN].some(Boolean)) {
				return 'LandingAgeSelection'
			} else {
				return 'LandingGenderSelection'
			}
		},
	},

	created() {
		this.resetAnalyticDataCounter()
		const utmCampaign = this.getQueryParam('utm_campaign')
			? decodeURIComponent(this.getQueryParam('utm_campaign'))
			: null
		const utmContent = this.getQueryParam('utm_content') ? decodeURIComponent(this.getQueryParam('utm_content')) : null
		const utmTerm = this.getQueryParam('utm_term') ? decodeURIComponent(this.getQueryParam('utm_term')) : null

		// We store initial funnel type to be able to track CRs for gender selection funnels
		this.$store.commit('location/setInitialFunnelType', this.appAreaConfig.funnel.slug)

		this.$analytic
			.setUserProperties({
				Device: { os, version, height: window.innerHeight, width: window.innerWidth },
				browserEngine,
				browserName,
				browserVersion,
				SurveyVersion: VUE_APP_VERSION,
				locale: this.$i18n.locale,
				CookieConsentActiveGroups: this.onetrustActiveGroups,
				hostname: this.hostname,
				from: this.getQueryParam('from'),
				AdgroupId: this.getQueryParam('adgroup_id'),
				AdId: this.getQueryParam('ad_id'),
				CampaignId: this.getQueryParam('campaign_id'),
				UtmGender: this.getQueryParam('utm_gender'),
				UtmMedium: this.getQueryParam('utm_medium'),
				UtmPartnerId: this.getQueryParam('utm_partner_id'),
				UtmSource: this.getQueryParam('utm_source'),
				UtmCampaign: utmCampaign,
				UtmContent: utmContent,
				UtmTerm: utmTerm,
			})
			.then(() => {
				this.$analytic.logEvent('ScreenView', { title: 'OnboardingWelcomeScreen' })
				this.$analytic.logEvent('OnboardingStart', { from: this.getQueryParam('from') })
			})

		Jet.logCoreEvent('OnboardingStart')
	},

	beforeRouteEnter(to, from, next) {
		if (from.path === '/') {
			OpenTelemetry.startSpan('vue-landing-mounted', {
				startTime: window.ot.startTime,
				attributes: { 'span.type': 'vue-landing-mounted' },
			})
		}
		next()
	},

	methods: {
		...mapMutations({
			resetAnalyticDataCounter: 'survey/resetAnalyticDataCounter',
		}),
	},
}
</script>
