<template>
	<Screen class="landing">
		<template #header>
			<BaseHeader theme="white" />
		</template>
		<template #content>
			<div class="container-fluid">
				<div class="landing__title">{{ content.title }}</div>
				<div class="landing__animation">
					<div id="animationContainer"></div>
				</div>
				<div class="landing__loader">
					<div class="landing__loader_label">{{ content.loader.label }}</div>
					<ProcessingBar
						:duration="processingBarDuration"
						:label="$t('pages.landing.loading_the_test')"
						:switch-inverse-text-color="false"
						theme="male-primary-contrasted"
						:compact="true"
						@onCompleted="goToWelcome"
					/>
					<div class="landing__loader_note">{{ content.loader.note }}</div>
				</div>

				<div class="landing__footer">
					<p>
						{{ $t('landing_male.app_description') }}
					</p>
					<p>
						{{ $t('landing_male.results_disclaimer') }}
					</p>
				</div>
			</div>
		</template>
	</Screen>
</template>

<script>
import { lottieLoadAnimation } from '@/helpers/lottie'
import { mapMutations } from 'vuex'
import sphereAnimation from '@/data/lottie-animations/sphereAnimation.json'
import OpenTelemetry from '@/modules/OpenTelemetry'

import Screen from '@/components/Screen.vue'
import BaseHeader from '@/components/BaseHeader.vue'
import ProcessingBar from '@/components/ProcessingBar.vue'
import { mergeTranslationsWithFallback } from '@/i18n'

export default {
	name: 'LandingMale',

	components: {
		Screen,
		BaseHeader,
		ProcessingBar,
	},

	computed: {
		nextRoute() {
			return 'male-survey'
		},

		processingBarDuration() {
			return 4000
		},

		content() {
			return mergeTranslationsWithFallback({
				type: 'male_funnel',
				key: this.getQueryParam('lpid'),
				fallback: {
					title: this.$t('landing_male.title'),
					loader: {
						label: this.$t('landing_male.loader.label'),
						note: this.$t('landing_male.loader.note'),
					},
				},
			})
		},
	},

	methods: {
		...mapMutations({
			updateProperty: 'survey/updateProperty',
		}),

		goToWelcome() {
			const goal = 'lose_weight'

			this.updateProperty({ property: 'goal', value: [goal], isData: true })

			this.$analytic.logEvent('OnboardingWelcomeScreenDone', {
				data: goal,
			})

			OpenTelemetry.startSpan('vue-landing-to-onboarding', {
				attributes: {
					'span.type': 'vue-landing-to-onboarding',
				},
			})

			this.$router.push({ name: this.nextRoute })
		},

		runAnimation() {
			lottieLoadAnimation(
				{
					container: document.getElementById('animationContainer'),
					renderer: 'svg',
					loop: true,
					autoplay: true,
					animationData: JSON.parse(JSON.stringify(sphereAnimation)),
				},
				1.7,
			)
		},
	},

	mounted() {
		this.runAnimation()

		OpenTelemetry.endSpan('vue-landing-mounted')
	},
}
</script>

<style lang="scss" scoped>
.landing {
	&__title {
		margin-top: 1rem;
		text-align: center;
		font-size: var(--size-18);
		line-height: var(--size-20);
		font-weight: 700;
	}

	&__animation {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;

		background-image: url('@/assets/landing/lottie-background.png'); // For faster LCP detection
	}

	&__loader {
		text-align: center;

		&_label {
			font-size: 1.1rem;
			font-weight: bold;
			margin-bottom: 0.8rem;
		}

		&_note {
			margin-top: 0.8rem;
			color: var(--text-color-secondary);
		}
	}

	&__footer {
		margin-top: 2.5rem;
		margin-bottom: 1.5rem;
		text-align: center;
		color: var(--text-color-secondary);
		font-size: 0.8rem;

		p {
			margin-bottom: 0;
			margin-top: 1.5rem;
		}
	}

	&__forbes {
		display: flex;
		justify-content: center;
		align-items: baseline;
		gap: var(--size-12);
		margin-top: var(--size-24);
		margin-bottom: var(--size-16);
		font-size: var(--size-16);
		line-height: var(--size-20);

		& svg {
			:deep(path) {
				fill: var(--Fill-Inverse);
			}
		}
	}
}
</style>
