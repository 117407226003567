<template>
	<Screen class="landing">
		<template #header>
			<BaseHeader theme="gray" logo-size="s" centered />
		</template>
		<template #content>
			<div class="container-fluid">
				<div class="landing__title">{{ content.title }}</div>
				<div class="landing__animation">
					<div id="animationContainer"></div>
				</div>
				<div class="landing__loader">
					<div class="landing__loader_label">{{ $t('landing_male.loader.label') }}</div>
					<ProcessingBar
						:duration="3500"
						:label="content.loader.label"
						:switch-inverse-text-color="false"
						theme="male-primary-contrasted"
						:compact="true"
						@onCompleted="goToWelcome"
					/>
					<div class="landing__loader_note">{{ content.loader.note }}</div>
				</div>

				<div class="landing__footer">
					<p>{{ content.feature_description }}</p>
					<p>{{ content.disclaimer }}</p>
				</div>
			</div>
		</template>
	</Screen>
</template>

<script>
import { lottieLoadAnimation } from '@/helpers/lottie'
import { mapGetters, mapMutations } from 'vuex'
import genderSelectionLoader from '@/data/lottie-animations/genderSelectionLoader.json'
import OpenTelemetry from '@/modules/OpenTelemetry'

import Screen from '@/components/Screen.vue'
import BaseHeader from '@/components/BaseHeader.vue'
import ProcessingBar from '@/components/ProcessingBar.vue'
import { mergeTranslationsWithFallback } from '@/i18n'

export default {
	name: 'LandingGenderSelection',

	components: {
		Screen,
		BaseHeader,
		ProcessingBar,
	},

	computed: {
		...mapGetters({
			getQueryParam: 'location/getQueryParam',
		}),

		nextRoute() {
			return this.MAIN_FUNNEL_ROUTE_NAME
		},

		content() {
			return mergeTranslationsWithFallback({
				type: 'main_loader',
				key: this.getQueryParam('lpid'),
				fallback: {
					title: this.$t('pages.landing.male_funnel.title'),
					loader: {
						label: this.$t('pages.landing.loading_the_test'),
						note: this.$t('landing_male.loader.note'),
					},
					feature_description: this.$t('landing_male.app_description'),
					disclaimer: this.$t('landing_male.results_disclaimer'),
				},
			})
		},
	},

	methods: {
		...mapMutations({
			updateProperty: 'survey/updateProperty',
		}),

		goToWelcome() {
			const goal = 'lose_weight'

			this.updateProperty({ property: 'goal', value: [goal], isData: true })

			this.$analytic.logEvent('OnboardingWelcomeScreenDone', {
				data: goal,
			})

			OpenTelemetry.startSpan('vue-landing-to-onboarding', {
				attributes: { 'span.type': 'vue-landing-to-onboarding' },
			})

			this.$router.push({ name: this.nextRoute })
		},

		runAnimation() {
			lottieLoadAnimation({
				container: document.getElementById('animationContainer'),
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: JSON.parse(JSON.stringify(genderSelectionLoader)),
				rendererSettings: {
					className: 'lottie-animation',
				},
			})
		},
	},

	mounted() {
		this.runAnimation()

		OpenTelemetry.endSpan('vue-landing-mounted')
	},
}
</script>

<style lang="scss" scoped>
.landing {
	background-color: var(--color-gray);

	&__title {
		margin-top: var(--size-8);
		text-align: center;
		font-size: var(--size-18);
		line-height: var(--size-22);
		font-weight: 700;
	}

	&__animation {
		max-width: 75%;
		margin: var(--size-8) auto;
		background-image: url('@/assets/landing/lottie-background.png'); // For faster LCP detection
	}

	&__loader {
		text-align: center;

		&_label {
			font-size: var(--size-16);
			font-weight: 600;
			margin-bottom: 0.8rem;
		}

		&_note {
			margin-top: 0.8rem;
			font-size: var(--size-14);
		}
	}

	&__footer {
		margin-top: 2rem;
		margin-bottom: 1.5rem;
		color: var(--text-color-tertiary);
		font-size: 0.875rem;
		line-height: 1.25rem;

		p {
			margin-bottom: 0;
			margin-top: 1.5rem;
		}
	}

	&__forbes {
		display: flex;
		justify-content: center;
		align-items: baseline;
		gap: var(--size-12);
		margin-top: var(--size-24);
		margin-bottom: var(--size-16);
		font-size: var(--size-16);
		line-height: var(--size-20);
	}
}
</style>
