var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Screen',{staticClass:"landing",class:{ landing_spreadsheet: _vm.IS_SPREADSHEET_CREATIVES_EXP },scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('BaseHeader',{staticClass:"mt-2",attrs:{"theme":"gray","centered":""}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"landing__title",class:{
					'mx-3': _vm.AREA.PILATES_FEMALE,
					landing__title_lg: _vm.IS_BELLY_TYPES_CREATIVES_WELCOME || _vm.IS_SPREADSHEET_CREATIVES_EXP,
				}},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(_vm.content.subtitle)?_c('div',{staticClass:"landing__subtitle",class:{ landing__subtitle_lg: _vm.IS_BELLY_TYPES_CREATIVES_WELCOME },domProps:{"innerHTML":_vm._s(_vm.content.subtitle)}}):_vm._e(),_c('div',{staticClass:"landing__animation",class:{
					landing__animation_img: _vm.IS_BELLY_TYPES_CREATIVES_WELCOME,
					landing__animation_spreadsheet: _vm.IS_SPREADSHEET_CREATIVES_EXP,
				}},[(_vm.IS_BELLY_TYPES_CREATIVES_WELCOME)?_c('img',{attrs:{"src":require("@/assets/landing/belly-types.png"),"alt":"","height":"173"}}):(_vm.IS_SPREADSHEET_CREATIVES_EXP_B)?_c('img',{attrs:{"src":require("@/assets/landing/spreadsheet.webp"),"alt":""}}):(_vm.IS_SPREADSHEET_CREATIVES_EXP_C)?_c('img',{attrs:{"src":require("@/assets/landing/spreadsheet.png"),"alt":""}}):_c('div',{attrs:{"id":"animationContainer"}})]),_c('div',{staticClass:"landing__loader"},[_c('div',{staticClass:"landing__loader_label"},[_vm._v(_vm._s(_vm.content.loader.label))]),_c('ProcessingBar',{attrs:{"duration":_vm.processingBarDuration,"label":_vm.$t('pages.landing.loading_the_test'),"switch-inverse-text-color":false,"theme":"male-primary-contrasted","compact":true},on:{"onCompleted":_vm.goToWelcome}}),_c('div',{staticClass:"landing__loader_note"},[_vm._v(_vm._s(_vm.content.loader.note))])],1),_c('div',{staticClass:"landing__footer"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('landing_male.app_description'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('landing_male.results_disclaimer'))+" ")])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }